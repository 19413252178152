<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <v-card class="mx-auto" max-width="344">
      <v-card-text>
        <h1>{{ quiz.title }}</h1>
        <p class="text-h4 text--primary">
          {{ quiz.description }}
        </p>
        <p>Title: {{ quiz.author }}</p>
        <div class="text--primary">
          Time Limit: {{ moment.utc(moment.duration(quiz.time_limit,'seconds').as('milliseconds')).format('HH:mm:ss') }}
        </div>
        <p>Highest Score: {{ quiz.high_score }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded depressed color="indigo" :to="{name: 'RunningQuiz', params: {id: quiz.id}}">
          Start test
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['quiz'],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'Quiz',
          disabled: true,
          href: '/quiz-view/'+this.$route.params.id,
        },
      ],
    }
  },
  created() {
    this.$router.push({path:`/quiz-view/${this.quiz.id}`})
  }
}
</script>
