<template>
  <v-app>
    <v-main class="login-background">
      <div class="d-flex align-center flex-column justify-center px-1 login-background-2">
        <v-card width="100%">
          <div class="card-header">
            <div class="d-flex align-center flex-column">
              <v-subheader class="display-1 mt-3">
                Terms and Conditions
              </v-subheader>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
              <v-row no-gutters class="px-3">
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Online Payments</v-subheader>
                </v-col>
                <v-col cols="12">
                  This online payment system is provided by Perfect Manpower Consultants. Perfect
Manpower Consultantsmay update these terms from time to time and any changes will be
effective immediately on being set out here. Please ensure you are aware of the current terms.
The country of domicile for Perfect Manpower Consultants is India.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Terms &amp; Conditions</v-subheader>
                </v-col>
                <v-col cols="12">
                  Please read these terms carefully before using the online payment facility. Using the online
payment facility on this website indicates that you accept these terms. If you do not accept
these terms do not use this facility.
                </v-col>
                <v-col cols="12">
                All payments are subject to the following conditions:-
                </v-col>
                <v-col cols="12">
                The description of services of match making are specific to your need, when you log in with
your unique password. Normally payment is required in advance (i.e. before you commence
your activity).
                </v-col>
                <v-col cols="12">
                All Fees quoted are in Indian Rupees. The Perfect Manpower Consultants reserves the right
to change the fees at any time.
                </v-col>
                <v-col cols="12">
                Your payment will normally reach the Perfect Manpower Consultants account to
which you are making a payment within two working days.
 We cannot accept liability for a payment not reaching the correct Perfect Manpower
Consultants account due to you quoting an incorrect account number or incorrect
personal details. Neither can we accept liability if payment is refused or declined by
the credit/debit card supplier for any reason.
 If the card supplier declines payment the Perfect Manpower Consultants is under no
obligation to bring this fact to your attention. You should check with your
bank/credit/debit card supplier that payment has been deducted from your account.
 In no event will the Perfect Manpower Consultants be liable for any damages
whatsoever arising out of the use, inability to use, or the results of use of this site, any
websites linked to this site, or the materials or information contained at any or all such
sites, whether based on warranty, contract, tort or any other legal theory and whether
or not advised of the possibility of such damages.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Refund Policy</v-subheader>
                </v-col>
                <v-col cols="12">
                  If the Customer leaves the Perfect Manpower Consultants before they complete their
service period, there shall be no entitlement to a refund of paid service fees.
                </v-col>
                <v-col cols="12">
                  Refunds, if applicable, at the discretion of the Management, will only be made to the
debit/credit card used for the original transaction. For the avoidance of doubt nothing
in this Policy shall require the Perfect Manpower Consultants to refund the Fees (or
part thereof) unless such Fees (or part thereof) have previously been paid.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Privacy Policy</v-subheader>
                </v-col>
                <v-col cols="12">
                  This Privacy Policy applies to all of the products, services and websites offered by
MERCHANT. Sometimes, we may post product specific privacy notices or Help Centre
materials to explain our products in more detail.
                </v-col>
                <v-col cols="12">
                  If you have any questions about this Privacy Policy, please feel free to contact us through our
website or write to us at
                </v-col>
                <v-col cols="12">
                  Information we collect and how we use it for our Perfect Manpower Consultants.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Changes to our Privacy Policy</v-subheader>
                </v-col>
                <v-col cols="12">
                  Perfect Manpower Consultants reserves the entire right to modify/amend/remove this privacy
statement anytime and without any reason. Nothing contained herein creates or is intended to
create a contract/agreement between Perfect Manpower Consultants and any user visiting the
MERCHANT website or providing identifying information of any kind.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">DND Policy</v-subheader>
                </v-col>
                <v-col cols="12">
                  If you wish to stop any further sms/email alerts/contacts from our side, all you need to do is
to send an email:-rbalait@gmail.com with your mobile numbers and you will be excluded
from the alerts list.
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Contact Details:</v-subheader>
                </v-col>
                <v-col cols="12">
                <pre>
Email: rbalait@gmail.com
Mobile No : 9841623426
Address :
PERFECT MANPOWER CONSULTANTS
PPK BizPark Door No,4/82, Survey No,117A,Earikkarai road,
Kundrathur,Chennai- 600069. LANDMARK: Lake Side Water Plant.
</pre>
                </v-col>
              </v-row>
          </v-card-text>
          
        </v-card>
        <v-btn
                color="primary"
                class="px-5"
                :to="{name: 'Homepage'}"
            >Back</v-btn>
        <h4>Copyright &copy; <span class="color-secondary">PMC</span></h4>

      </div>

    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {  },
  name: "PaidStatus",
  data: () => ({
    dialog: true,
    tab: 0,
    authenticated: true,
  }),
 
};
</script>

<style scoped>
.login-background {
  height: 90%;
  width: 100vw;
  background-color: #f9f9f9;
  background-repeat: repeat;
}
.login-background-2 {
  height: 100%;
  width: 100vw;
}
.color-secondary {
  color: #2BA5B6
}
</style>
