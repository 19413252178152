<template>
  <v-app>
    <v-main class="login-background">
      <div class="d-flex align-center flex-column justify-center px-1 login-background-2">
        <v-card width="500px">
          <div class="card-header">
            <div class="d-flex align-center flex-column">
                <img v-if="this.payment.status !== 'success'" src="@/assets/img/failure.jpg">
                <img v-if="this.payment.status === 'success'" src="@/assets/img/success.jpg">
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="logo-wrapper d-flex flex-column align-center pb-3">
              <v-subheader class="headline font-weight-bold">Transaction Details</v-subheader>
            </div>
            <v-divider></v-divider>
              <v-row no-gutters class="px-3">
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Amount : </v-subheader> {{payment.amount}}
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Transaction Number : </v-subheader> <p>{{payment.transaction_number}}</p>
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Transaction Date : </v-subheader> <p> {{payment.transaction_date}} </p>
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Order ID : </v-subheader> <p> {{payment.order_id}} </p>
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Status: </v-subheader> <p> {{payment.status.replace(/\_/g, ' ')}} </p>
                </v-col>
                <v-col cols="12">
                  <v-subheader class="subtitle-2 px-0">Details: </v-subheader> <p> {{payment.status_desc}} </p>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-7 pb-5">
          <v-btn v-if="authenticated"
                color="primary"
                class="px-5"
                :to="{name: 'Homepage'}"
            >Home</v-btn>
            <v-btn v-else
                color="primary"
                class="px-5"
                :to="{name: 'Login'}"
            >Login</v-btn>
          </v-card-actions>
        </v-card>
        <h4>Copyright &copy; <span class="color-secondary">PMC</span></h4>

      </div>

    </v-main>
  </v-app>
</template>

<script>
import { LOGOUT } from "@/store/actions/type";

export default {
  components: {  },
  name: "PaidStatus",
  data: () => ({
    dialog: true,
    tab: 0,
    loader: false,
    authenticated: true,
    payment: {}
  }),
  created() {
    const res = this.$route.query.data
    const result = window.atob(decodeURIComponent(res))
    if(result) {
      result.split('&').map((item) => {
        const [ k, v ] = item.split('=')
        v ? this.payment[k] = v : null
      })
    }
    const utc_date = decodeURIComponent(this.payment.transaction_date);
    const date = new Date(utc_date);
    this.payment.transaction_date = date.toLocaleString();
    this.payment.email = decodeURIComponent(this.payment.email);
    this.payment.status_desc = decodeURIComponent(this.payment.status_desc.replace(/\+/g, ' '));
    if(this.payment.status === 'success') {
      this.$store.dispatch(LOGOUT).then(() => {
        this.authenticated = false;
      });
    }
    
  },
};
</script>

<style scoped>
.login-background {
  height: 90%;
  width: 100vw;
  background-color: #f9f9f9;
  background-repeat: repeat;
}
.login-background-2 {
  height: 100%;
  width: 100vw;
}
.color-secondary {
  color: #2BA5B6
}
</style>
