<template>
  <v-app>
    <v-main class="login-background">
      <div class="d-flex align-center flex-column justify-center px-1 login-background-2">
        <v-card width="100%">
          <div class="card-header">
            <div class="d-flex align-center flex-column">
              <v-subheader class="style1">
                PERFECT  MANPOWER CONSULTANTS
              </v-subheader>
                  <v-subheader class="style2 px-0">Training the art of caring</v-subheader>
                  <v-subheader class="style3 px-0">Please Subscribe &amp; Join our team</v-subheader>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
              <v-row no-gutters class="px-3">
                <v-col cols="12">
                  <ul>
  <li class="style4">This quiz aims to help student  nurses develop their critical thinking skills and test your knowledge with this  nursing quiz.</li>
  <li class="style4">This quiz is designed for practice  as well as knowledge. You will get to know many new things as well. 
    <blockquote>
      <blockquote>
        <blockquote>
          <blockquote>
            <blockquote>
              <p class="style4"> *****All  the best for a perfect score!&nbsp;*****</p>
              <blockquote>
                <p class="style5">Website  : http:/helplah.info/login</p>
              </blockquote>
            </blockquote>
          </blockquote>
        </blockquote>
      </blockquote>
    </blockquote>
  </li>
</ul>

<p class="style4">You  can subscribe now and get access for the quiz.</p>
<p class="style4">*Number  of Access: 10</p>
<p class="style4">*  1000 Top Interview Questions is specially designed for hiring nurses.</p>
<div class="style4">&bull;Subscription Fee : &#8377; 3,000</div>
<div class="style4">&bull;Renewal Fee : &#8377; 1,500</div>
<p class="style4">*Time  to subscribe your offer now!</p>
<p class="style4">&nbsp;</p>
                </v-col>
                
              </v-row>
          </v-card-text>
          
        </v-card>
        <v-btn
                color="primary"
                class="px-5"
                :to="{name: 'Homepage'}"
            >Back</v-btn>
        <h4>Copyright &copy; <span class="color-secondary">PMC</span></h4>

      </div>

    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {  },
  name: "PaidStatus",
  data: () => ({
    dialog: true,
    tab: 0,
    authenticated: true,
  }),
 
};
</script>

<style scoped>
.login-background {
  height: 90%;
  width: 100vw;
  background-color: #f9f9f9;
  background-repeat: repeat;
}
.login-background-2 {
  height: 100%;
  width: 100vw;
}
.color-secondary {
  color: #2BA5B6
}
.style1 {
	color: #FF0000;
	font-weight: bold;
	font-size: 24px;
}
.style2 {
	font-size: 24px;
	font-weight: bold;
	color: #4884FF;
}
.style3 {
	font-size: 24px;
	color: #FF0000;
}
.style4 {font-size: 18px}
.style5 {font-size: 18px; font-weight: bold; }
</style>
